
import { createSlice } from '@reduxjs/toolkit';
import { densityMaxTimelineValue, TScaleDensity } from '@th-common/interfaces/player/scale';
import {
  IBaseVideoDownloadTrack,
} from '@th-common/interfaces/video/video-request';
import { VideoDownloadUtils } from '@th-common/utils/video-player/video-download-utils';
import dayjs from 'dayjs';

import { startTime } from '../video-playback/gps-fake-data';

import { TimelineScaleUtils } from './utils/timeline-scale-utils';
import { IVideoPlayerScaleState } from './video-player-scale-state.interface';

const initialState: IVideoPlayerScaleState = {
  videoRequestStartDayjs: dayjs(),
  videoRequestEndDayjs: dayjs(),
  videoRequestMin: 0,
  videoRequestMax: 0,
  scaleRange: [0, 0],
  scaleDensity: TScaleDensity.None,
  scaleValue: [0, 0],
  hoveredFrame: null,
  hoveredFrameTooltip: '',
  hoveredFramePositionX: 0,
};

export const slice = createSlice({
  name: 'videoPlayerScale',
  initialState,
  reducers: {
    setScaleDensity: (state, { payload }: { payload: { scaleDensity: TScaleDensity; currentTimelineValue: number } }) => {
      const zoomDiff = Math.min(
        densityMaxTimelineValue[payload.scaleDensity],
        state.videoRequestMax - state.videoRequestMin,
      );

      const [
        zoomStartDiff,
        zoomEndDiff,
      ] = TimelineScaleUtils.getZoomDiffRelatedToHoveredFrame(zoomDiff, payload.currentTimelineValue, state.scaleValue);

      const newScaleValue: [number, number] = [
        Math.max(payload.currentTimelineValue - zoomStartDiff, 0),
        Math.min(payload.currentTimelineValue + zoomEndDiff, state.videoRequestMax),
      ];

      const {
        scaleValue,
        scaleRange,
        scaleDensity,
      } = TimelineScaleUtils.getScaleState(
        newScaleValue,
        state,
      );

      state.scaleValue = scaleValue;
      state.scaleRange = scaleRange;
      state.scaleDensity = scaleDensity;
    },
    setCenterFrame: (state, { payload }: { payload: number }) => {
      const currentTimelineValue = payload;
      const scaleDiff = (state.scaleValue[1] - state.scaleValue[0]) / 2;
      let scaleStart = currentTimelineValue - scaleDiff;
      let scaleEnd = currentTimelineValue + scaleDiff;

      if (scaleStart < state.videoRequestMin) {
        scaleEnd += (state.videoRequestMin - scaleStart);
      }

      if (scaleEnd > state.videoRequestMax) {
        scaleStart -= (scaleEnd - state.videoRequestMax);
      }

      const {
        scaleValue,
        scaleRange,
        scaleDensity,
      } = TimelineScaleUtils.getScaleState(
        [
          Math.max(scaleStart, state.videoRequestMin),
          Math.min(scaleEnd, state.videoRequestMax),
        ],
        state,
      );

      state.scaleValue = scaleValue;
      state.scaleRange = scaleRange;
      state.scaleDensity = scaleDensity;
    },
    setVideoSource: (state, { payload }: { payload: { file: File; durationInSeconds: number } }) => {
      const {
        videoRequestStartDayjs,
        videoRequestMin,
        videoRequestMax,
      } = VideoDownloadUtils.getVideoRequestState(startTime.toISOString(), startTime.add(payload.durationInSeconds, 'second').toISOString());

      const {
        scaleValue,
        scaleRange,
        scaleDensity,
      } = TimelineScaleUtils.getScaleState(
        [videoRequestMin, videoRequestMax],
        {
          ...state,
          videoRequestMax,
        },
      );

      state.scaleValue = scaleValue;
      state.scaleRange = scaleRange;
      state.scaleDensity = scaleDensity;

      state.videoRequestStartDayjs = videoRequestStartDayjs;
      state.videoRequestMin = videoRequestMin;
      state.videoRequestMax = videoRequestMax;
    },
    setHoveredFrame: (state, { payload }: { payload: { hoveredFrame: number | null; timeFormat: string; x: number | null } }) => {
      if (payload.x === state.hoveredFramePositionX) {
        return;
      }

      if (payload.x === null) {
        payload.x = state.hoveredFramePositionX;
      }

      state.hoveredFramePositionX = payload.x;

      if (payload.hoveredFrame === state.hoveredFrame) {
        return;
      }

      if (payload.hoveredFrame === null) {
        state.hoveredFrame = null;
        state.hoveredFrameTooltip = '';
        return;
      }

      state.hoveredFrame = payload.hoveredFrame;
      state.hoveredFrameTooltip = state.videoRequestStartDayjs.add(payload.hoveredFrame, 'ms').format(payload.timeFormat);
    },
    setScaleValue: (state, { payload }: { payload: [number, number] }) => {
      const newScaleRange = payload;

      const reachedMinScaleValue = TimelineScaleUtils.reachedMinScaleValue(newScaleRange);

      if (reachedMinScaleValue) {
        return;
      }

      const {
        scaleValue,
        scaleRange,
        scaleDensity,
      } = TimelineScaleUtils.getScaleState(
        [
          Math.max(newScaleRange[0], state.videoRequestMin),
          Math.min(newScaleRange[1], state.videoRequestMax),
        ],
        state,
      );

      state.scaleValue = scaleValue;
      state.scaleRange = scaleRange;
      state.scaleDensity = scaleDensity;
    },
    zoomIn: (state, { payload }: { payload: { currentTimelineValue: number } }) => {
      const zoomDiff = (state.scaleValue[1] - state.scaleValue[0]) * 0.02;

      const [
        zoomStartDiff,
        zoomEndDiff,
      ] = TimelineScaleUtils.getZoomDiffRelatedToHoveredFrame(
        zoomDiff,
        state.hoveredFrame || payload.currentTimelineValue!,
        state.scaleValue,
      );

      const scaleMinValue = +(state.scaleValue[0] + zoomStartDiff).toFixed(1);
      const scaleMaxValue = +(state.scaleValue[1] - zoomEndDiff).toFixed(1);

      const reachedMinScaleValue = TimelineScaleUtils.reachedMinScaleValue([scaleMinValue, scaleMaxValue]);

      if (reachedMinScaleValue) {
        return;
      }

      const {
        scaleValue,
        scaleRange,
        scaleDensity,
      } = TimelineScaleUtils.getScaleState(
        [
          Math.min(scaleMinValue, scaleMaxValue),
          Math.max(scaleMaxValue, scaleMinValue),
        ],
        state,
      );

      state.scaleValue = scaleValue;
      state.scaleRange = scaleRange;
      state.scaleDensity = scaleDensity;
    },
    zoomOut: (state, { payload }: { payload: { currentTimelineValue: number } }) => {
      const zoomDiff = (state.scaleValue[1] - state.scaleValue[0]) * 0.02;

      const [
        zoomStartDiff,
        zoomEndDiff,
      ] = TimelineScaleUtils.getZoomDiffRelatedToHoveredFrame(
        zoomDiff,
        state.hoveredFrame || payload.currentTimelineValue,
        state.scaleValue,
      );

      const {
        scaleValue,
        scaleRange,
        scaleDensity,
      } = TimelineScaleUtils.getScaleState(
        [
          Math.max(state.scaleValue[0] - zoomStartDiff, state.videoRequestMin),
          Math.min(state.scaleValue[1] + zoomEndDiff, state.videoRequestMax),
        ],
        state,
      );

      state.scaleValue = scaleValue;
      state.scaleRange = scaleRange;
      state.scaleDensity = scaleDensity;
    },
    prevTimeFrame: (state) => {
      if (state.scaleValue[0] === state.videoRequestMin) {
        return;
      }

      const scaleDiff = (state.scaleValue[1] - state.scaleValue[0]);

      const scaleStart = Math.max(state.scaleValue[0] - scaleDiff, state.videoRequestMin);
      const scaleEnd = scaleStart + scaleDiff;

      const newScaleRange: [number, number] = [scaleStart, scaleEnd];

      const {
        scaleValue,
        scaleRange,
        scaleDensity,
      } = TimelineScaleUtils.getScaleState(
        [
          Math.max(newScaleRange[0], state.videoRequestMin),
          Math.min(newScaleRange[1], state.videoRequestMax),
        ],
        state,
      );

      state.scaleValue = scaleValue;
      state.scaleRange = scaleRange;
      state.scaleDensity = scaleDensity;
    },
    nextTimeFrame: (state) => {
      if (state.scaleValue[1] === state.videoRequestMax) {
        return;
      }

      const scaleDiff = (state.scaleValue[1] - state.scaleValue[0]);

      const scaleEnd = Math.min(state.scaleValue[1] + scaleDiff, state.videoRequestMax);
      const scaleStart = scaleEnd - scaleDiff;

      const newScaleRange: [number, number] = [scaleStart, scaleEnd];

      const {
        scaleValue,
        scaleRange,
        scaleDensity,
      } = TimelineScaleUtils.getScaleState(
        [
          Math.max(newScaleRange[0], state.videoRequestMin),
          Math.min(newScaleRange[1], state.videoRequestMax),
        ],
        state,
      );

      state.scaleValue = scaleValue;
      state.scaleRange = scaleRange;
      state.scaleDensity = scaleDensity;
    },
    setVideoDownloadTracks: (state, { payload }: { payload: IBaseVideoDownloadTrack[] }) => {
      const {
        videoRequestStartDayjs,
        videoRequestEndDayjs,
        videoRequestMin,
        videoRequestMax,
      } = VideoDownloadUtils.getVideoRequestState(payload[0].start, payload[payload.length - 1].end);

      const {
        scaleValue,
        scaleRange,
        scaleDensity,
      } = TimelineScaleUtils.getScaleState(
        [videoRequestMin, videoRequestMax],
        {
          ...state,
          videoRequestMax,
        },
      );

      state.videoRequestStartDayjs = videoRequestStartDayjs;
      state.videoRequestEndDayjs = videoRequestEndDayjs;
      state.videoRequestMin = videoRequestMin;
      state.videoRequestMax = videoRequestMax;
      state.scaleValue = scaleValue;
      state.scaleRange = scaleRange;
      state.scaleDensity = scaleDensity;
    },
    reset: () => initialState,
  },
});

export default slice.reducer;
