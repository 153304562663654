/* eslint-disable @typescript-eslint/naming-convention */
import { Components, Theme } from '@mui/material/styles';

export const MuiFormControl: Components<Omit<Theme, 'components'>>['MuiFormControl'] = {
  styleOverrides: {
    root: {
      '&:has(.MuiInputBase-root.Mui-disabled)': {
        opacity: 0.38,
        cursor: 'not-allowed',
      },
    },
  },
};
