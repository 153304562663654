
import { Components, Theme } from '@mui/material/styles';

import { typography } from '../typography';

export const MuiSelect: Components<Omit<Theme, 'components'>>['MuiSelect'] = {
  styleOverrides: {
    outlined: {
      ...typography.labelLarge,
    },
  },
};
