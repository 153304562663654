import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { videoDownloadApi } from '@th-common/api/video-download.api';
import { IJ1939Data, IJ1939State } from '@th-common/interfaces/video/j1939-data';

export interface IVideoPlaybackJ1939State {
  j1939JSONList: string[];
  j1939Data: IJ1939Data[];
}

const initialState: IVideoPlaybackJ1939State = {
  j1939JSONList: [],
  j1939Data: [],
};

export const slice = createSlice({
  name: 'videoPlaybackJ1939',
  initialState,
  reducers: {
    reset: (): IVideoPlaybackJ1939State => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<IVideoPlaybackJ1939State>) => {
    builder
      .addMatcher(videoDownloadApi.endpoints.getVideoDownload.matchFulfilled, (state, { payload }) => {
        state.j1939JSONList = payload.j1939;
      })
      .addMatcher(videoDownloadApi.endpoints.getVideoDownloadJ1939Data.matchFulfilled, (state, { payload }) => {
        state.j1939Data = payload.flatMap(
          ({ Data, Properties, NumberProperties }) => {
            return Data.map(dataEntry => ({
              time: dataEntry.TS,
              states: Properties
                .map((prop, index) => ({
                  name: prop.Name,
                  value: prop.Values[dataEntry.States[index]],
                })) as IJ1939State[],
              numbers: NumberProperties
                .map((propName, index) => ({
                  name: propName,
                  value: dataEntry.Numbers[index].toString(),
                })) as IJ1939State[],
            }));
          },
        );
      });
  },
});

export default slice.reducer;
