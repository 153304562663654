
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { userGroupsApi } from '@th-common/api/user-group.api';
import { TUserGroupSettingsTab } from '@th-common/enums/user-groups.enum';
import {
  IUserGroup, IUserGroupDevices, IUserGroupPermissions, IUserGroupPermissionTreeItem,
} from '@th-common/interfaces/user-groups/user-groups';

import { PermissionUtils } from './utils/permission-utils';

export interface IUserGroupsState {
  userGroups: IUserGroup[];
  groupPermissions: IUserGroupPermissionTreeItem[];
  initialSelectedPermissions: string[];
  selectedGroupId: number | null;
  selectedUserId: number | null;
  selectedGroupPermissions: string[];
  initialCanAccessAllDevices: boolean;
  canAccessAllDevices: boolean;
  userGroupDevices: IUserGroupDevices[];
  initialUserGroupDeviceIds: number[];
  selectedUserGroupDeviceIds: number[];
  userGroupDetailsInProgress: boolean;
  deviceGroupViewId: number | null;
  editGroupActiveTab: TUserGroupSettingsTab;
  // Form
  newGroupName: string;
}

const initialState: IUserGroupsState = {
  userGroups: [],
  groupPermissions: [],
  initialSelectedPermissions: [],
  selectedGroupId: null,
  selectedUserId: null,
  selectedGroupPermissions: [],
  initialCanAccessAllDevices: false,
  canAccessAllDevices: false,
  userGroupDevices: [],
  initialUserGroupDeviceIds: [],
  selectedUserGroupDeviceIds: [],
  userGroupDetailsInProgress: false,
  deviceGroupViewId: null,
  editGroupActiveTab: TUserGroupSettingsTab.GroupPermissions,
  // Form
  newGroupName: '',
};

const userGroupPermissionsReducer = (state: IUserGroupsState, { payload }: { payload: IUserGroupPermissions }): void => {
  const [
    groupPermissions,
    initialSelectedPermissions,
  ] = PermissionUtils.parseUserGroupPermissions(payload);

  state.initialCanAccessAllDevices = payload.canAccessAllDevices;
  state.canAccessAllDevices = payload.canAccessAllDevices;
  state.groupPermissions = groupPermissions;
  state.initialSelectedPermissions = initialSelectedPermissions;
  state.selectedGroupPermissions = initialSelectedPermissions;
  state.userGroupDevices = payload.userGroupDevices;
  const initialUserGroupDeviceIds = payload.userGroupDevices
    .filter((device) => device.isSelected)
    .map((device) => device.deviceGroupId);
  state.initialUserGroupDeviceIds = initialUserGroupDeviceIds;
  state.selectedUserGroupDeviceIds = initialUserGroupDeviceIds;
};

export const slice = createSlice({
  name: 'user-groups',
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedGroupId: (state, { payload }: { payload: number | null }) => {
      if (payload === -1 && state.selectedGroupId === -1) {
        state.canAccessAllDevices = state.initialCanAccessAllDevices;
      } else {
        state.groupPermissions = [];
        state.userGroupDevices = [];
      }
      state.selectedGroupId = payload;
      state.initialSelectedPermissions = [];
      state.selectedGroupPermissions = [];
      state.initialUserGroupDeviceIds = [];
      state.selectedUserGroupDeviceIds = [];
      state.userGroupDetailsInProgress = false;
      state.deviceGroupViewId = null;
      state.newGroupName = '';
      state.editGroupActiveTab = initialState.editGroupActiveTab;
    },
    setEditGroupActiveTab: (state, { payload }: { payload: TUserGroupSettingsTab }) => {
      state.editGroupActiveTab = payload;
    },
    setSelectedUserId: (state, { payload }: { payload: number | null }) => {
      state.selectedUserId = payload;
      state.deviceGroupViewId = null;
    },
    setSelectedGroupPermissions: (state, { payload }: { payload: string[] }) => {
      state.selectedGroupPermissions = payload;
    },
    setInitialSelectedPermissions: (state, { payload }: { payload: string[] }) => {
      state.initialSelectedPermissions = payload;
    },
    setInitialUserGroupDeviceIds: (state, { payload }: { payload: number[] }) => {
      state.initialUserGroupDeviceIds = payload;
    },
    setInitialCanAccessAllDevices: (state, { payload }: { payload: boolean }) => {
      state.initialCanAccessAllDevices = payload;
    },
    setCanAccessAllDevices: (state, { payload }: { payload: boolean }) => {
      state.canAccessAllDevices = payload;
    },
    selectUserGroupDevice: (state, { payload }: { payload: number }) => {
      state.selectedUserGroupDeviceIds.push(payload);
    },
    setUserGroupDetailsInProgress: (state, { payload }: { payload: boolean }) => {
      state.userGroupDetailsInProgress = payload;
    },
    unselectUserGroupDevice: (state, { payload }: { payload: number }) => {
      state.selectedUserGroupDeviceIds = state.selectedUserGroupDeviceIds.filter((id) => id !== payload);
    },
    setDeviceGroupViewId: (state, { payload }: { payload: number | null }) => {
      state.deviceGroupViewId = payload;
    },
    // Form
    setNewGroupName: (state, { payload }: { payload: string }) => {
      state.newGroupName = payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IUserGroupsState>) => {
    builder
      .addMatcher(userGroupsApi.endpoints.getUserGroups.matchFulfilled, (state, { payload }) => {
        state.userGroups = payload;
      })
      .addMatcher(userGroupsApi.endpoints.getUserGroupPermissions.matchFulfilled, userGroupPermissionsReducer)
      .addMatcher(userGroupsApi.endpoints.getNewUserGroupPermissions.matchFulfilled, userGroupPermissionsReducer);
  },
});

export default slice.reducer;
