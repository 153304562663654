import { configureStore } from '@reduxjs/toolkit';
import { apiRoot } from '@th-common/store/api';
import authReducer from '@th-common/store/auth/slice';
import commonStoreReducer from '@th-common/store/common/slice';
import fleetReducer from '@th-common/store/fleet/slice';
import geographicalQueryReducer from '@th-common/store/geographical-query/slice';
import geographicalQueryDetailsReducer from '@th-common/store/geographical-query-details/slice';
import jobReducer from '@th-common/store/job/slice';
import jobWizardReducer from '@th-common/store/job-wizard/slice';
import lookupReducer from '@th-common/store/lookup/slice';
import manageDeviceGroupsReducer from '@th-common/store/manage-device-groups/slice';
import markedVideoReducer from '@th-common/store/marked-video/slice';
import notificationReducer from '@th-common/store/notification/slice';
import signalRStoreReducer from '@th-common/store/signalr/slice';
import systemStoreReducer from '@th-common/store/system/slice';
import userGroupsReducer from '@th-common/store/user-groups/slice';
import userProfileReducer from '@th-common/store/user-profile/slice';
import vehicleDatabaseReducer from '@th-common/store/vehicle-database/slice';
import videoArchiveReducer from '@th-common/store/video-archive/slice';
import videoPlaybackDevicesReducer from '@th-common/store/video-playback-devices/slice';
import videoQueryReducer from '@th-common/store/video-query/slice';

import cameraVerificationReducer from '@/app/app/fleets/modals/DeviceDetailsModal/stores/camera-verification/slice';
import jobHistoryReducer from '@/app/app/fleets/modals/DeviceDetailsModal/stores/job-history/slice';
import logbookStoreReducer from '@/app/app/fleets/modals/DeviceDetailsModal/stores/logbook/slice';
import statusHistoryReducer from '@/app/app/fleets/modals/DeviceDetailsModal/stores/status-history/slice';
import jobExecutionsReducer from '@/app/app/jobs/log/modals/JobHistoryDetails/stores/job-execution/slice';

import { authListenerMiddleware } from './middleware/auth.middleware';
import { signalRListenerMiddleware } from './middleware/signalr.middleware';
import { dvrApiRoot } from './dvr-api';
import { videoPlayerStores } from './video-player';

const store = configureStore({
  reducer: {
    [apiRoot.reducerPath]: apiRoot.reducer,
    [dvrApiRoot.reducerPath]: dvrApiRoot.reducer,

    auth: authReducer,
    common: commonStoreReducer,
    signalR: signalRStoreReducer,
    fleet: fleetReducer,
    job: jobReducer,
    jobHistory: jobHistoryReducer,
    jobExecutions: jobExecutionsReducer,
    logbook: logbookStoreReducer,
    notification: notificationReducer,
    statusHistory: statusHistoryReducer,
    system: systemStoreReducer,
    userProfile: userProfileReducer,
    cameraVerification: cameraVerificationReducer,
    jobWizard: jobWizardReducer,
    videoQuery: videoQueryReducer,
    lookup: lookupReducer,
    geographicalQuery: geographicalQueryReducer,
    geographicalQueryDetails: geographicalQueryDetailsReducer,
    markedVideo: markedVideoReducer,
    videoArchive: videoArchiveReducer,
    vehicleDatabase: vehicleDatabaseReducer,
    userGroups: userGroupsReducer,
    manageDeviceGroups: manageDeviceGroupsReducer,
    videoPlaybackDevices: videoPlaybackDevicesReducer,
    ...videoPlayerStores,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(
    apiRoot.middleware,
    dvrApiRoot.middleware,
    authListenerMiddleware.middleware,
    signalRListenerMiddleware.middleware,
  ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
