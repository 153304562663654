/* eslint-disable @typescript-eslint/naming-convention */
import { Components, Theme } from '@mui/material/styles';

import { typography } from '../typography';

export const MuiTableContainer: Components<Omit<Theme, 'components'>>['MuiTableContainer'] = {
  styleOverrides: {
    root: {
      borderRadius: 0,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      '&:has( .MuiTableBody-root .MuiTableRow-root.tableRowError:nth-child(1):hover)': {
        '.MuiTableHead-root .MuiTableRow-root': {
          '--th-table-row-border-bottom-color': 'var(--_th-table-row-border-color-error)',
        },
      },
      '&:has( .MuiTableBody-root .MuiTableRow-root.Mui-selected.tableRowError:nth-child(1))': {
        '.MuiTableHead-root .MuiTableRow-root': {
          '--th-table-row-border-bottom-color': 'var(--_th-table-row-border-color-error)',
          '--th-table-row-border-bottom-width': 'var(--_th-border-width-error-selected)',
        },
      },
    },
  },
};

// @ts-ignore
export const MuiTableRow: Components<Omit<Theme, 'components'>>['MuiTableRow'] = {
  styleOverrides: {
    root: {
      '&.MuiTableRow-root': {
        '--_th-border-width-default': '0.5px',
        '--_th-border-width-error-selected': '1px',
        '--_th-table-row-border-color-default': 'var(--color-outline-variant)',
        '--_th-table-row-border-color-error': 'var(--color-on-error-container)',
        '--th-table-row-border-width': 'var(--_th-border-width-default)',
        '--th-table-row-border-bottom-width': 'var(--th-table-row-border-width)',
        '--th-table-row-border-right-width': 'var(--th-table-row-border-width)',
        '--th-table-row-border-left-width': 'var(--th-table-row-border-width)',
        '--th-table-row-border-bottom-color': 'var(--_th-table-row-border-color-default)',
        '--th-table-row-border-right-color': 'transparent',
        '--th-table-row-border-left-color': 'transparent',
        '&:has(+ .tableRowError:hover)': {
          '--th-table-row-border-bottom-color': 'var(--_th-table-row-border-color-error)',
          '--th-table-row-border-bottom-width': 'var(--_th-border-width-default)',
        },
        '&:has(+ .tableRowError.Mui-selected)': {
          '--th-table-row-border-bottom-color': 'var(--_th-table-row-border-color-error)',
          '--th-table-row-border-bottom-width': 'var(--_th-border-width-error-selected)',
        },
      },
      '&.MuiTableRow-hover:hover': {
        '--th-table-background': 'var(--color-surf-container-low)',
        '--dots-menu-visibility': 'visible',
      },
      '&.Mui-selected': {
        '&:not(.tableRowError)': {
          '--th-table-background': 'var(--color-secondary-container)',
          '--th-table-row-color': 'var(--color-on-secondary-container)',
          '&:hover': {
            '--th-table-background': 'var(--color-secondary-container-high)',
          },
        },
        '&.tableRowError': {
          '--th-table-row-border-bottom-color': 'var(--_th-table-row-border-color-error)',
          '--th-table-row-border-left-color': 'var(--_th-table-row-border-color-error)',
          '--th-table-row-border-right-color': 'var(--_th-table-row-border-color-error)',
          '--th-table-row-border-width': 'var(--_th-border-width-error-selected)',
          '&:hover': {
            '--th-table-background': 'var(--color-error-container)',
            '--th-table-row-border-width': 'var(--_th-border-width-error-selected)',
          },

          '&:has(+ .tableRowError)': {
            '--th-table-row-border-bottom-width': 'var(--_th-border-width-error-selected)',
          },
        },
      },
      '&.tableRowError': {
        '--th-table-background': 'var(--color-error-container)',
        '--th-table-row-color': 'var(--_th-table-row-border-color-error)',

        '&:hover': {
          '--th-table-background': 'var(--color-error-container)',
          '--th-table-row-border-bottom-color': 'var(--_th-table-row-border-color-error)',
          '--th-table-row-border-left-color': 'var(--_th-table-row-border-color-error)',
          '--th-table-row-border-right-color': 'var(--_th-table-row-border-color-error)',
          '--th-table-row-border-width': 'var(--_th-border-width-default)',
        },
      },
    },
  },
};

export const MuiTableCell: Components<Omit<Theme, 'components'>>['MuiTableCell'] = {
  styleOverrides: {
    root: {
      color: 'var(--th-table-row-color, var(--color-on-surf-variant))',
      borderColor: 'var(--th-table-row-border-bottom-color, var(--color-outline-variant))',
      borderBottomWidth: 'var(--th-table-row-border-bottom-width, 1px)',
      '&:first-child': {
        borderLeft: 'solid var(--th-table-row-border-left-width, 1px) var(--th-table-row-border-left-color, transparent)',
      },
      '&:last-child': {
        borderRight: 'solid var(--th-table-row-border-right-width, 1px) var(--th-table-row-border-right-color, transparent)',
      },
      ...typography.body3,
    },
    head: {
      ...typography.labelMedium,
      backgroundColor: 'var(--th-table-background, transparent)',
      whiteSpace: 'nowrap',
      '&.MuiTableCell-sizeSmall': {
        paddingLeft: '0.5rem',
      },
      '&.MuiTableCell-sizeMedium': {
        padding: '0.5rem 1rem',
      },
      '& .MuiTableSortLabel-root': {
        paddingLeft: '0.125rem',
        marginLeft: '-0.125rem',
        width: '100%',
        justifyContent: 'space-between',
      },
      '& .MuiTableSortLabel-root:hover': {
        background: 'var(--color-surf-container-highest)',
        borderRadius: '0.25rem',
        color: 'var(--color-on-surf)',
      },
    },
    body: {
      backgroundColor: 'var(--th-table-background, transparent)',
      '&.MuiTableCell-sizeSmall': {
        paddingLeft: '0.5rem',
      },
      '&.MuiTableCell-sizeMedium': {
        padding: '0.75rem 1rem',
      },
      '&.MuiTableCell-paddingCheckbox > *': {
        margin: '0 !important',
      },
    },
  },
};
