import { IUserGroupPermissionItem, IUserGroupPermissions, IUserGroupPermissionTreeItem } from '@th-common/interfaces/user-groups/user-groups';

export namespace PermissionUtils {
  export const getPermissionItemId = (permissionItem: IUserGroupPermissionItem): string => {
    return `${permissionItem.name}-${permissionItem.permissionDto?.id || '0'}`;
  };

  export const parsePermissionItem = (permissionItem: IUserGroupPermissionItem, parentPermissionId?: string): [
    IUserGroupPermissionTreeItem,
    string[],
  ] => {
    const selectedChildrenItems: string[] = [];
    const permissionItemId = `${parentPermissionId ? `${parentPermissionId}-` : ''}${getPermissionItemId(permissionItem)}`;

    if (permissionItem.isSelected) {
      selectedChildrenItems.push(permissionItemId);
    }

    const [
      parsedChildrenItems,
      selectedItems,
    ] = permissionItem.children.reduce((acc, childItem) => {
      const [parsedChildItem, selectedChildItems] = parsePermissionItem(childItem, permissionItemId);
      acc[0].push(parsedChildItem);
      acc[1].push(...selectedChildItems);
      return acc;
    }, [[], []] as [IUserGroupPermissionTreeItem[], string[]]);

    return [
      {
        id: permissionItemId,
        label: permissionItem.name,
        isSelected: permissionItem.isSelected,
        children: parsedChildrenItems,
      },
      selectedChildrenItems.concat(selectedItems),
    ];
  };

  export const parseUserGroupPermissions = (userGroupPermissions: IUserGroupPermissions): [
    IUserGroupPermissionTreeItem[],
    string[],
  ] => {
    const groupPermissionsArray = userGroupPermissions ? [userGroupPermissions.permissions] : [];

    return groupPermissionsArray.reduce((acc, groupPermissionsItem) => {
      const [
        parsedGroupPermissionItem,
        selectedPermissionItems,
      ] = parsePermissionItem(groupPermissionsItem);
      acc[0].push(parsedGroupPermissionItem);
      acc[1].push(...selectedPermissionItems);
      return acc;
    }, [[], []] as [
      IUserGroupPermissionTreeItem[],
      string[],
    ]);
  };
}
