
import { Components, Theme } from '@mui/material/styles';

export const MuiAccordion: Components<Omit<Theme, 'components'>>['MuiAccordion'] = {
  styleOverrides: {
    root: {
      borderRadius: '0 !important',
    },
  },
};
