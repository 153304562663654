
import { Components, Theme } from '@mui/material/styles';

function container(): Element {
  return document.fullscreenElement ?? document.body;
}

export const MuiTooltip: Components<Omit<Theme, 'components'>>['MuiTooltip'] = {
  defaultProps: {
    disableInteractive: true,
    PopperProps: {
      container,
    },
  },
};
