
import { createSlice } from '@reduxjs/toolkit';
import { IVideoBookmark } from '@th-common/interfaces/video/bookmark';

interface IVideoPlayerBookmarkState {
  bookmarks: IVideoBookmark[];
  activeBookmarkIndex: number | null;
}

const initialState: IVideoPlayerBookmarkState = {
  bookmarks: [],
  activeBookmarkIndex: null,
};

export const slice = createSlice({
  name: 'videoPlayerBookmark',
  initialState,
  reducers: {
    addBookmark: (state, { payload }: { payload: string }) => {
      const bookmark = {
        value: payload,
        note: '',
        saved: false,
      };

      if (state.activeBookmarkIndex !== null) {
        const activeBookmark = state.bookmarks[state.activeBookmarkIndex];
        if (activeBookmark.saved) {
          state.bookmarks = [...state.bookmarks, bookmark];
          state.activeBookmarkIndex = state.bookmarks.length - 1;
          return;
        }
        state.bookmarks[state.activeBookmarkIndex] = bookmark;
        return;
      }

      state.bookmarks = [...state.bookmarks, bookmark];
      state.activeBookmarkIndex = state.bookmarks.length - 1;
    },
    saveActiveBookmark: (state, { payload }: { payload: IVideoBookmark }) => {
      if (state.activeBookmarkIndex !== null) {
        state.bookmarks[state.activeBookmarkIndex] = {
          ...payload,
          saved: true,
        };
      }

      state.activeBookmarkIndex = null;
    },
    cancelActiveBookmark: (state) => {
      if (state.activeBookmarkIndex !== null && !state.bookmarks[state.activeBookmarkIndex].saved) {
        state.bookmarks = state.bookmarks.filter((_, index) => index !== state.activeBookmarkIndex);
      }

      state.activeBookmarkIndex = null;
    },
    clearBookmarks: (state) => {
      state.bookmarks = [];
      state.activeBookmarkIndex = null;
    },
    setActiveBookmark: (state, { payload }: { payload: number | null }) => {
      state.activeBookmarkIndex = payload === null ? null : payload;
    },
    reset: () => initialState,
  },
});

export default slice.reducer;
