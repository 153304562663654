
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { dateKindToDate } from '@th-common/components/form/DateKindPicker/index.helpers';
import { TOrderByDirection } from '@th-common/components/material/MatTable/types/table-order-by';
import { TDateKind } from '@th-common/enums/date-kind.enum';
import { IJobExecutionNotification, IJobExecutionUpdateNotification } from '@th-common/enums/signalr-methods.enum';
import {
  IJobHistory, IJobHistoryFilters, IJobHistoryFiltersFormValues, IJobHistorySearchRequest,
} from '@th-common/interfaces/job-history';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { DateTimeUtils } from '@th-common/utils/dateTime';
import dayjs from 'dayjs';

import { api as apiJobHistory } from './api';

export interface IJobHistoryState {
  items: IJobHistory[];
  pagination: Omit<IPagedResult<IJobHistory>, 'items'>;
  searchRequest: IJobHistorySearchRequest;
  filterDateKind: TDateKind;
  lastTimeUpdated: string;
}

const [defaultFromDate, defaultToDate] = dateKindToDate(TDateKind.Recent);

export const defaultFilters: IJobHistoryFilters = {
  jobExecutionStatus: null,
  jobType: null,
  fromDate: DateTimeUtils.onlyDateRequest(defaultFromDate),
  toDate: DateTimeUtils.onlyDateRequest(defaultToDate),
};

function convertFormValuesDateToSearchRequestDate(
  { fromDate, toDate }: Pick<IJobHistoryFiltersFormValues, 'fromDate' | 'toDate'>,
): Pick<IJobHistoryFilters, 'fromDate' | 'toDate'> {
  return {
    fromDate: DateTimeUtils.onlyDateRequest(fromDate),
    toDate: DateTimeUtils.onlyDateRequest(toDate),
  };
}

function convertFormValueToSearchRequestFilters(formValues: IJobHistoryFiltersFormValues): IJobHistoryFilters {
  return {
    jobExecutionStatus: formValues.jobExecutionStatus === 'All' ? null : formValues.jobExecutionStatus,
    jobType: formValues.jobType === 'All' ? null : formValues.jobType,
    ...convertFormValuesDateToSearchRequestDate(formValues),
  };
}

interface ISetFilterDatePayload {
  payload: Pick<IJobHistoryFiltersFormValues, 'fromDate' | 'toDate'>;
}

const initialState: IJobHistoryState = {
  items: [],
  pagination: {
    page: 1,
    pageCount: 0,
    totalCount: 0,
  },
  searchRequest: {
    page: 1,
    pageSize: 24,
    searches: [],
    searchAsOneWord: true,
    orderBy: [
      {
        fieldName: 'executionStartTime',
        ascending: false,
      },
    ],
    ...defaultFilters,
  },
  filterDateKind: TDateKind.Recent,
  lastTimeUpdated: dayjs().toISOString(),
};

export const slice = createSlice({
  name: 'jobHistory',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
      lastTimeUpdated: dayjs().toISOString(),
    }),
    formFilter: (state, { payload }) => {
      const { dateKind, ...filters } = payload;

      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        ...convertFormValueToSearchRequestFilters(filters),
      };

      state.filterDateKind = dateKind;
    },
    setFilterDate: (state, { payload }: ISetFilterDatePayload) => {
      const { fromDate, toDate } = convertFormValuesDateToSearchRequestDate(payload);

      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        fromDate,
        toDate,
      };
    },
    setFilterDateKind: (state, { payload }: { payload: TDateKind }) => {
      state.filterDateKind = payload;
    },
    setPage: (state, { payload }) => {
      state.searchRequest.page = payload;
    },
    setOrderBy: (state, { payload: [fieldName, direction] }: { payload: [string, TOrderByDirection] }) => {
      state.searchRequest = {
        ...state.searchRequest,
        orderBy: state.searchRequest.orderBy.length === 0 || direction ? [
          {
            fieldName,
            ascending: direction === 'asc',
          },
        ] : [],
      };
    },
    signalRJobHistoryUpdateNotification: (state, { payload }: { payload: IJobExecutionUpdateNotification }) => {
      const {
        jobExecutionId, percentDone, executionStatus,
      } = payload;

      state.items = state.items.map((jobHistory): IJobHistory => {
        if (jobHistory.id === jobExecutionId) {
          return {
            ...jobHistory,
            jobExecutionStatus: executionStatus,
            percentDone,
          };
        }
        return jobHistory;
      });
    },
    signalRJobExecutionNotification: (state, { payload }: { payload: IJobExecutionNotification }) => {
      state.items = [{
        ...payload,
        isNew: true,
      }, ...state.items];
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IJobHistoryState>) => {
    builder
      .addMatcher(apiJobHistory.endpoints.getJobHistory.matchFulfilled, (state, { payload }) => {
        const { items, ...pagination } = payload;

        state.items = items;
        state.pagination = pagination;
        state.lastTimeUpdated = dayjs().toISOString();
      });
  },
});

export const { signalRJobHistoryUpdateNotification, signalRJobExecutionNotification } = slice.actions;
export default slice.reducer;
