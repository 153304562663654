import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { dateKindToDate } from '@th-common/components/form/DateKindPicker/index.helpers';
import { TOrderByDirection } from '@th-common/components/material/MatTable/types/table-order-by';
import { TDateKind } from '@th-common/enums/date-kind.enum';
import {
  IGeographicalQuery,
  IGeographicalQueryFilters,
  IGeographicalQueryFiltersFormValues,
  IGeographicalQuerySearchRequest,
  TGeographicalQueryFiltered,
} from '@th-common/interfaces/geographical-query';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { DateTimeUtils } from '@th-common/utils';
import dayjs from 'dayjs';

import { api as apiGeographicalQuery } from './api';

function convertFormValuesDateToSearchRequestDate(
  { fromDate, toDate }: Pick<IGeographicalQueryFiltersFormValues, 'fromDate' | 'toDate'>,
): Pick<IGeographicalQueryFilters, 'fromDate' | 'toDate'> {
  return {
    fromDate: DateTimeUtils.onlyDateRequest(fromDate),
    toDate: DateTimeUtils.onlyDateRequest(toDate),
  };
}

function convertFormValueToSearchRequestFilters(formValues: IGeographicalQueryFiltersFormValues): IGeographicalQueryFilters {
  const userKey = formValues.userKey === 'All' || formValues.userKey === 'MineOnly' ? null : formValues.userKey;
  const mineOnly = formValues.userKey === 'MineOnly';

  return {
    mineOnly,
    userKey,
    jobExecutionStatus: formValues.jobExecutionStatus === 'All' ? null : formValues.jobExecutionStatus,
    ...convertFormValuesDateToSearchRequestDate(formValues),
  };
}

interface ISetFilterDatePayload {
  payload: Pick<IGeographicalQueryFiltersFormValues, 'fromDate' | 'toDate'>;
}

export interface IGeographicalQueryState {
  geographicalQueries: IGeographicalQuery[];
  searchRequest: IGeographicalQuerySearchRequest;
  pagination: Omit<IPagedResult<IGeographicalQuery>, 'items'>;
  filterDateKind: TDateKind;
  lastTimeUpdated: string;
  selectedGeographicalQuery: IGeographicalQuery | null;
  activeQueryIndex: number | null;
  pageLoading: boolean;
}

const [defaultFromDate, defaultToDate] = dateKindToDate(TDateKind.AllTime);

export const defaultFilters: TGeographicalQueryFiltered = {
  userKey: null,
  jobExecutionStatus: null,
  fromDate: DateTimeUtils.onlyDateRequest(defaultFromDate),
  toDate: DateTimeUtils.onlyDateRequest(defaultToDate),
  orderBy: [{
    fieldName: 'createTime',
    ascending: false,
  }],
};

const initialState: IGeographicalQueryState = {
  geographicalQueries: [],
  searchRequest: {
    page: 1,
    pageSize: 36,
    searches: [],
    searchAsOneWord: true,
    mineOnly: false,
    ...defaultFilters,
  },
  filterDateKind: TDateKind.AllTime,
  pagination: {
    page: 1,
    pageCount: 0,
    totalCount: 0,
  },
  lastTimeUpdated: dayjs().toISOString(),
  selectedGeographicalQuery: null,
  activeQueryIndex: null,
  pageLoading: false,
};

export const slice = createSlice({
  name: 'geographicalQuery',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
      lastTimeUpdated: dayjs().toISOString(),
    }),
    resetPage: (state) => {
      state.geographicalQueries = [];
      state.searchRequest.page = 1;
      state.selectedGeographicalQuery = null;
    },
    setPage: (state, { payload }: { payload: number }) => {
      state.searchRequest.page = payload;
      state.selectedGeographicalQuery = null;
    },
    formFilter: (state, { payload }) => {
      const { dateKind, ...filters } = payload;

      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        ...convertFormValueToSearchRequestFilters(filters),
      };

      state.filterDateKind = dateKind;
      state.selectedGeographicalQuery = null;
    },
    setFilterDate: (state, { payload }: ISetFilterDatePayload) => {
      const { fromDate, toDate } = convertFormValuesDateToSearchRequestDate(payload);

      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        fromDate,
        toDate,
      };
      state.selectedGeographicalQuery = null;
    },
    setFilterDateKind: (state, { payload }: { payload: TDateKind }) => {
      state.filterDateKind = payload;
      state.selectedGeographicalQuery = null;
    },
    search: (state, { payload }) => {
      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        searches: payload ? [payload] : [],
      };
      state.selectedGeographicalQuery = null;
    },
    selectGeographicalQuery: (state, { payload }: { payload: IGeographicalQuery | null }) => {
      state.selectedGeographicalQuery = payload;
    },
    setActiveQueryIndex: (state, { payload }: { payload: number | null }) => {
      state.activeQueryIndex = payload;

      if (payload === null) {
        state.selectedGeographicalQuery = null;
      } else {
        const query = state.geographicalQueries[payload];

        if (query) {
          state.selectedGeographicalQuery = query;
        }
      }
    },
    setPageLoading: (state, { payload }: { payload: boolean }) => {
      state.pageLoading = payload;
    },
    setOrderBy: (state, { payload: [fieldName, direction] }: { payload: [string, TOrderByDirection] }) => {
      state.searchRequest = {
        ...state.searchRequest,
        orderBy: state.searchRequest.orderBy.length === 0 || direction ? [
          {
            fieldName,
            ascending: direction === 'asc',
          },
        ] : [],
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IGeographicalQueryState>) => {
    builder
      .addMatcher(apiGeographicalQuery.endpoints.getGeographicalQueries.matchFulfilled, (state, { payload }) => {
        const { items, ...pagination } = payload;

        state.geographicalQueries = items || [];
        state.pagination = pagination;
        state.lastTimeUpdated = dayjs().toISOString();

        if (state.selectedGeographicalQuery && !state.geographicalQueries.find((vq) => vq.id === state.selectedGeographicalQuery?.id)) {
          state.selectedGeographicalQuery = null;
        }

        if (state.activeQueryIndex !== null) {
          state.selectedGeographicalQuery = items[state.activeQueryIndex] || null;
        }
      });
  },
});

export default slice.reducer;
