/* eslint-disable @typescript-eslint/naming-convention */

import { Components, Theme } from '@mui/material/styles';

export const MuiCheckbox: Components<Omit<Theme, 'components'>>['MuiCheckbox'] = {
  styleOverrides: {
    root: {
      color: 'var(--color-on-surf-variant)',
      padding: '0.5rem',
    },
  },
};

export const MuiFormControlLabel: Components<Omit<Theme, 'components'>>['MuiFormControlLabel'] = {
  styleOverrides: {
    root: {
      '&.Mui-disabled': {
        color: 'rgba(27, 27, 32, 0.38)',
      },
    },
  },
};
