/* eslint-disable @typescript-eslint/naming-convention */
import { Components, Theme } from '@mui/material/styles';

import { typography } from '../typography';

export const MuiMenu: Components<Omit<Theme, 'components'>>['MuiMenu'] = {
  defaultProps: {
    elevation: 0,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
  styleOverrides: {
    root: {
      '& .MuiPaper-root': {
        borderRadius: 4,
        boxShadow: '0px 2px 6px 2px #00000026, 0px 1px 2px 0px #0000004D',
        minWidth: 200,
        color: 'var(--color-on-surf)',
        backgroundColor: 'var(--color-surf-container)',
      },
    },
  },
};

export const MuiMenuItem: Components<Omit<Theme, 'components'>>['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      '& .MuiFormControlLabel-label': {
        ...typography.labelLarge,
      },
    },
  },
};
