import { densityMaxTimelineValue, densityOrderLowToHigh, TScaleDensity } from '@th-common/interfaces/player/scale';

import { IVideoPlayerScaleState } from '../video-player-scale-state.interface';

export namespace TimelineScaleUtils {
  export const getZoomDiffRelatedToHoveredFrame = (
    zoomDiff: number,
    hoveredFrame: number,
    scaleValue: [number, number],
  ): [number, number] => {
    const hoveredFrameToStart = hoveredFrame - scaleValue[0];
    const hoveredFrameToEnd = scaleValue[1] - hoveredFrame;

    const scaleDiff = scaleValue[1] - scaleValue[0];

    const zoomStartMultiplier = +(hoveredFrameToStart / scaleDiff).toFixed(3);
    const zoomEndMultiplier = +(hoveredFrameToEnd / scaleDiff).toFixed(3);

    const zoomStartDiff = zoomDiff * zoomStartMultiplier;
    const zoomEndDiff = zoomDiff * zoomEndMultiplier;

    return [zoomStartDiff, zoomEndDiff];
  };

  export const reachedMinScaleValue = (scaleValue: [number, number]): boolean => {
    const rangeDiff = scaleValue[1] - scaleValue[0];
    const halfMinuteInMs = 30 * 1000;

    return rangeDiff < halfMinuteInMs;
  };

  export const getScaleState = (
    scaleValue: [number, number],
    state: IVideoPlayerScaleState,
  ): Pick<IVideoPlayerScaleState, 'scaleValue' | 'scaleRange' | 'scaleDensity'> => {
    const scaleDiff = scaleValue[1] - scaleValue[0];

    const scaleDensity = densityOrderLowToHigh.find(densityKey => scaleDiff <= densityMaxTimelineValue[densityKey]) as TScaleDensity;

    let scaleRange = state.scaleRange;

    if (state.scaleDensity === TScaleDensity.None) {
      // If scale range is not set, set it to scale value
      scaleRange = scaleValue;
    } else if (scaleDensity !== state.scaleDensity) {
      const scaleDensityMaxTime = densityMaxTimelineValue[scaleDensity];

      if (state.hoveredFrame !== null) {
        const [
          scaleRangeStartDiff,
          scaleRangeEndDiff,
        ] = getZoomDiffRelatedToHoveredFrame(scaleDensityMaxTime, state.hoveredFrame, scaleRange);
        scaleRange[0] = Math.max((state.videoRequestMax / 2) - scaleRangeStartDiff, 0);
        scaleRange[1] = Math.min((state.videoRequestMax / 2) + scaleRangeEndDiff, state.videoRequestMax);
      } else {
        const scaleDensityHalf = scaleDensityMaxTime / 2;

        scaleRange = [
          Math.max((state.videoRequestMax / 2) - scaleDensityHalf, 0),
          Math.min((state.videoRequestMax / 2) + scaleDensityHalf, state.videoRequestMax),
        ] as [number, number];
      }
    }

    const scaleRangeDiff = scaleRange[1] - scaleRange[0];

    if (scaleValue[0] <= scaleRange[0]) {
      // If scale slider moved to the left and is out of scale range, set scale range to new scale value
      scaleRange[0] = scaleValue[0];
      scaleRange[1] = scaleRange[0] + scaleRangeDiff;
    } else if (scaleValue[1] >= scaleRange[1]) {
      // If scale slider moved to the right and is out of scale range, set scale range to new scale value
      scaleRange[1] = scaleValue[1];
      scaleRange[0] = scaleRange[1] - scaleRangeDiff;
    }

    return {
      scaleValue,
      scaleRange,
      scaleDensity,
    };
  };
}
