/* eslint-disable @typescript-eslint/naming-convention */
import { Components, Theme } from '@mui/material/styles';

export const MuiToggleButton: Components<Omit<Theme, 'components'>>['MuiToggleButton'] = {
  styleOverrides: {
    root: {
      padding: '0.5rem 2rem',
      color: 'var(--color-on-surf-variant)',
      borderRadius: '0.5rem',
      textTransform: 'capitalize',
      borderColor: 'var(--color-outline)',
      borderWidth: '1px',
      '&.Mui-selected, &.Mui-selected.Mui-disabled': {
        color: 'var(--color-on-secondary-container)',
        borderColor: 'var(--color-primary)',
      },
      '&.Mui-disabled': {
        color: 'var(--color-on-surf-variant)',
        borderColor: 'var(--color-outline)',
      },
    },
  },
  variants: [
    {
      props: { color: 'tertiary' },
      style: {
        '&.Mui-selected': {
          backgroundColor: 'var(--color-tertiary-container)',
          ':hover': {
            backgroundColor: 'var(--color-tertiary-container-high)',
          },
          '&.Mui-disabled': {
            backgroundColor: 'var(--color-tertiary-container-low)',
            borderColor: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.26)',
          },
        },
        ':hover': {
          backgroundColor: 'var(--color-tertiary-container-high)',
        },
      },
    },
  ],
};

export const MuiToggleButtonGroup: Components<Omit<Theme, 'components'>>['MuiToggleButtonGroup'] = {
  styleOverrides: {
    root: {
      '&.Mui-disabled': {
        opacity: '0.38',
      },
    },
    firstButton: {
      '&.Mui-selected': {
        borderRightColor: 'var(--color-outline)',
      },
    },
    lastButton: {
      '&.Mui-selected': {
        borderLeftColor: 'var(--color-outline)',
      },
    },
    vertical: {
      '& .MuiToggleButtonGroup-lastButton': {
        borderTopColor: 'var(--color-outline)',
      },
    },
  },
};
