
import { HubConnection } from '@microsoft/signalr';
import { createSlice } from '@reduxjs/toolkit';

interface IState {
  data: any;
  isAuthenticated: boolean | null;
  hubConnectionInProgress: boolean;
  hubConnection: HubConnection | null;
}
const initialState: IState = {
  data: 'testState',
  isAuthenticated: null,
  hubConnectionInProgress: false,
  hubConnection: null,
};
export const slice = createSlice({
  name: 'signalR',
  initialState,
  reducers: {
    reset: () => initialState,
    setAuthenticated: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
    setHubConnectionInProgress: (state, { payload }: { payload: boolean }) => {
      state.hubConnectionInProgress = payload;
    },
    setHubConnection: (state, { payload }: { payload: HubConnection }) => {
      state.hubConnection = payload;
    },
    stopHubConnection: (state) => {
      state.hubConnection = null;
    },
  },
  extraReducers: () => {},
});

export const { setHubConnectionInProgress, setHubConnection, stopHubConnection } = slice.actions;
export default slice.reducer;
