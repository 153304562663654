
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { IJobExecutionUpdateNotification } from '@th-common/enums/signalr-methods.enum';
import {
  IDeviceInfo,
  IGeographicalQueryDeviceExecution,
  IGeographicalQueryDeviceExecutionInfo,
} from '@th-common/interfaces/geographical-query-job';
import dayjs from 'dayjs';

import { geographicalQueryDetailsApi } from './api';

export interface IGeographicalQueryDetailsState {
  deviceInfos: IDeviceInfo[];
  lastTimeUpdated: string;
  geographicalQueryDeviceExecutionsData: IGeographicalQueryDeviceExecution[];
  selectGeographicalQueryExecution: IGeographicalQueryDeviceExecution | null;
  selectedGeographicalQueryDeviceExecutionInfo: IGeographicalQueryDeviceExecutionInfo | null;
}

const initialState: IGeographicalQueryDetailsState = {
  deviceInfos: [],
  lastTimeUpdated: dayjs().toISOString(),
  geographicalQueryDeviceExecutionsData: [],
  selectGeographicalQueryExecution: null,
  selectedGeographicalQueryDeviceExecutionInfo: null,
};

export const slice = createSlice({
  name: 'geographicalQueryDetails',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
      lastTimeUpdated: dayjs().toISOString(),
      selectGeographicalQueryExecution: null,
    }),
    selectGeographicalQueryExecution: (state, { payload }) => {
      state.selectGeographicalQueryExecution = payload;
    },
    signalRGeographicalQueryExecutionUpdateNotification: (state, { payload }: { payload: IJobExecutionUpdateNotification }) => {
      const {
        jobExecutionId, percentDone, executionStatus, videoDownloadId,
      } = payload;

      state.geographicalQueryDeviceExecutionsData = state.geographicalQueryDeviceExecutionsData.map(
        (gq): IGeographicalQueryDeviceExecution => {
          if (gq.jobExecutionId === jobExecutionId) {
            return {
              ...gq,
              jobExecutionStatus: executionStatus,
              percentDone,
              downloadId: videoDownloadId,
            };
          }
          return gq;
        },
      );
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IGeographicalQueryDetailsState>) => {
    builder
      .addMatcher(geographicalQueryDetailsApi.endpoints.getGeographicalQueryJob.matchFulfilled, (state, { payload }) => {
        const { deviceInfos } = payload;

        state.deviceInfos = deviceInfos;
        state.lastTimeUpdated = dayjs().toISOString();
      })
      .addMatcher(geographicalQueryDetailsApi.endpoints.getGeographicalQueryDeviceExecutionInfo.matchFulfilled, (state, { payload }) => {
        state.selectedGeographicalQueryDeviceExecutionInfo = payload;
      })
      .addMatcher(geographicalQueryDetailsApi.endpoints.getGeographicalQueryDeviceExecutions.matchFulfilled, (state, { payload }) => {
        state.geographicalQueryDeviceExecutionsData = payload;
      });
  },
});

export const { signalRGeographicalQueryExecutionUpdateNotification } = slice.actions;
export default slice.reducer;
