import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { databaseApi } from '@th-common/api/database.api';
import { TOrderByDirection } from '@th-common/components/material/MatTable/types/table-order-by';
import { TDeviceFilterKind } from '@th-common/enums/device-filter-kind';
import { TCombinedFiltersType } from '@th-common/enums/status/operational-status.enum';
import {
  IDatabaseDevice,
  IVehicleDatabaseFiltersFormValues,
  IVehicleDatabaseSearchRequest,
  TVehicleDatabaseFiltered,
} from '@th-common/interfaces/database.interface';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { convertToDeviceGroupFilter } from '@th-common/utils/convert-to-group-filter';
import dayjs from 'dayjs';

export interface IVehicleDatabaseState {
  devices: IDatabaseDevice[];
  combinedFilter: TCombinedFiltersType;
  dataListLoading: boolean;
  searchRequest: IVehicleDatabaseSearchRequest;
  pagination: Omit<IPagedResult<IDatabaseDevice>, 'items'>;
  lastTimeUpdated: string;
  addEditDeviceOpened: boolean;
  editDeviceData: IDatabaseDevice | null;
}

export const defaultFilters: TVehicleDatabaseFiltered = {
  deviceGroupId: null,
  deviceFilterKind: TDeviceFilterKind.All,
};

const initialState: IVehicleDatabaseState = {
  devices: [],
  combinedFilter: 'All',
  dataListLoading: false,
  searchRequest: {
    page: 1,
    pageSize: 36,
    searches: [],
    searchAsOneWord: true,
    orderBy: [
      {
        fieldName: 'id',
        ascending: true,
      },
    ],
    ...defaultFilters,
  },
  pagination: {
    page: 1,
    pageCount: 0,
    totalCount: 0,
  },
  lastTimeUpdated: dayjs().toISOString(),
  addEditDeviceOpened: false,
  editDeviceData: null,
};

function convertFormValueToSearchRequestFilters(formValues: IVehicleDatabaseFiltersFormValues): TVehicleDatabaseFiltered {
  return {
    ...(convertToDeviceGroupFilter(formValues.deviceGroupId)),
  };
}

export const slice = createSlice({
  name: 'vehicleDatabase',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
      lastTimeUpdated: dayjs().toISOString(),
    }),
    formFilterDevices: (state, { payload }) => {
      state.combinedFilter = payload.combinedFilter;
      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        ...convertFormValueToSearchRequestFilters(payload),
      };
    },
    setDataListLoading: (state, { payload }: { payload: boolean }) => {
      state.dataListLoading = payload;
    },
    search: (state, { payload }) => {
      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        searches: payload ? [payload] : [],
      };
    },
    setPage: (state, { payload }: { payload: number }) => {
      state.searchRequest.page = payload;
    },
    setOrderBy: (state, { payload: [fieldName, direction] }: { payload: [string, TOrderByDirection] }) => {
      state.searchRequest = {
        ...state.searchRequest,
        orderBy: state.searchRequest.orderBy.length === 0 || direction ? [
          {
            fieldName,
            ascending: direction === 'asc',
          },
        ] : [],
      };
    },
    openAddEditDevice: (state, { payload }: { payload: IDatabaseDevice | null }) => {
      state.addEditDeviceOpened = true;
      state.editDeviceData = payload;
    },
    closeAddEditDevice: (state) => {
      state.addEditDeviceOpened = false;
      state.editDeviceData = null;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IVehicleDatabaseState>) => {
    builder
      .addMatcher(databaseApi.endpoints.getDatabaseDevices.matchFulfilled, (state, { payload }) => {
        const { items, ...pagination } = payload;
        state.devices = items || [];
        state.pagination = pagination;
        state.lastTimeUpdated = dayjs().toISOString();
      });
  },
});

export default slice.reducer;
