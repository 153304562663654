import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { StorageAuthKeys } from '@th-common/constants/auth';
import { TAuthFeature } from '@th-common/enums/auth-feature.enum';
import { StorageUtils } from '@th-common/utils/storage';

import { initializeAuth, logout, renewAuth } from '../auth/slice';
import { RootState } from '../store';

export const authListenerMiddleware = createListenerMiddleware();
authListenerMiddleware.startListening({
  matcher: isAnyOf(
    initializeAuth,
    renewAuth,
    logout,
  ),
  effect: (action, listenerApi) => {
    const state = listenerApi.getState() as RootState;

    // if initializeAuth action is dispatched, save token, refreshToken, expiresIn and authMethod to storage
    if (action.type === initializeAuth.type || action.type === renewAuth.type) {
      const storage = state.auth.isRemember ? 'localStorage' : 'sessionStorage';
      StorageUtils.set<string>(StorageAuthKeys.TOKEN, state.auth.token!, storage);
      StorageUtils.set<string>(StorageAuthKeys.REFRESH_TOKEN, state.auth.refreshToken!, storage);
      StorageUtils.set<number>(StorageAuthKeys.TOKEN_EXPIRES, state.auth.expiresIn!, storage);
      StorageUtils.set<string>(StorageAuthKeys.AUTH_METHOD, state.auth.authenticationMethod!, storage);
      StorageUtils.set<boolean>(StorageAuthKeys.IS_REMEMBER, state.auth.isRemember, storage);
      StorageUtils.set<TAuthFeature[]>(StorageAuthKeys.FEATURES, state.auth.features, storage);
    }

    // if logout action is dispatched, clear storages
    if (action.type === logout.type) {
      const env = StorageUtils.search<{ apiBaseUrl: string }>('env', 'sessionStorage');
      StorageUtils.clear();
      StorageUtils.set('env', env, 'sessionStorage');
    }
  },
});
