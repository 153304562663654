import { TDeviceCommunicationScope } from '@th-common/enums/device-communication-scope.enum';
import { TDiscoveryStatus } from '@th-common/enums/discovery-status.enum';
import { IJobHistory } from '@th-common/interfaces/job-history';
import { IStatusHistory } from '@th-common/interfaces/status-history';

import { TJobExecutionStatus } from './job/job-execution-status.enum';
import { TJobState } from './job/job-state.enum';

export enum TSignalRMethods {
  JobExecutionUpdateNotification = 'JobExecutionUpdateNotification',
  DeviceStatusList = 'DeviceStatusList',
  DeviceStatusNotification = 'DeviceStatusNotification',
  JobExecutionNotification = 'JobExecutionNotification',
}

export interface IJobExecutionUpdateNotification {
  jobExecutionId: number;
  jobId: number;
  percentDone: number;
  jobState: TJobState;
  executionStatus: TJobExecutionStatus;
  videoDownloadId: number | null;
}

export interface IDeviceStatusListNotification {
  deviceId: number;
  discoveryStatus: TDiscoveryStatus;
  deviceCommunicationScope: TDeviceCommunicationScope;
}

export type IDeviceStatusNotification = IStatusHistory;
export type IJobExecutionNotification = IJobHistory;
