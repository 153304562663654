import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { videoDownloadApi } from '@th-common/api/video-download.api';
import { IVideoAvailData } from '@th-common/interfaces/video/avail-data';

export interface IVideoPlaybackAvailState {
  availJSONList: string[];
  availData: IVideoAvailData[];
}

const initialState: IVideoPlaybackAvailState = {
  availJSONList: [],
  availData: [],
};

export const slice = createSlice({
  name: 'videoPlaybackAvail',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<IVideoPlaybackAvailState>) => {
    builder
      .addMatcher(videoDownloadApi.endpoints.getVideoDownload.matchFulfilled, (state, { payload }) => {
        state.availJSONList = payload.avail || [];
      })
      .addMatcher(videoDownloadApi.endpoints.getVideoDownloadAvailData.matchFulfilled, (state, { payload }) => {
        state.availData = payload;
      });
  },
});

export default slice.reducer;
