import { TMenuItem } from '../navigation-menu';

export enum TScaleDensity {
  Wide = 'Wide',
  Broad = 'Broad',
  Medium = 'Medium',
  Focus = 'Focus',
  Precise = 'Precise',
  Pinpoint = 'Pinpoint',
  None = 'None',
}

const minute = 60 * 1000;
const hour = 60 * minute;

export const densityMaxTimelineValue: Record<TScaleDensity, number> = {
  [TScaleDensity.Wide]: Infinity,
  [TScaleDensity.Broad]: 24 * hour,
  [TScaleDensity.Medium]: 6 * hour,
  [TScaleDensity.Focus]: 1.5 * hour,
  [TScaleDensity.Precise]: 30 * minute,
  [TScaleDensity.Pinpoint]: 5 * minute,
  [TScaleDensity.None]: 0,
};

export const densityOrderLowToHigh = [
  TScaleDensity.None,
  TScaleDensity.Pinpoint,
  TScaleDensity.Precise,
  TScaleDensity.Focus,
  TScaleDensity.Medium,
  TScaleDensity.Broad,
  TScaleDensity.Wide,
];

export const densityToIcon: Record<TScaleDensity, string> = {
  [TScaleDensity.Wide]: 'density-wide',
  [TScaleDensity.Broad]: 'density-broad',
  [TScaleDensity.Medium]: 'density-medium',
  [TScaleDensity.Focus]: 'density-focus',
  [TScaleDensity.Precise]: 'density-precise',
  [TScaleDensity.Pinpoint]: 'density-pinpoint',
  [TScaleDensity.None]: '',
};

export const scaleDensityMenu: TMenuItem[] = [
  {
    id: TScaleDensity.Wide,
    icon: densityToIcon[TScaleDensity.Wide],
    title: `${TScaleDensity.Wide}: ${densityMaxTimelineValue[TScaleDensity.Broad] / hour}+ hrs`,
  },
  {
    id: TScaleDensity.Broad,
    icon: densityToIcon[TScaleDensity.Broad],
    title: `${TScaleDensity.Broad}: ${densityMaxTimelineValue[TScaleDensity.Medium] / hour}-${densityMaxTimelineValue[TScaleDensity.Broad] / hour} hrs`,
  },
  {
    id: TScaleDensity.Medium,
    icon: densityToIcon[TScaleDensity.Medium],
    title: `${TScaleDensity.Medium}: ${densityMaxTimelineValue[TScaleDensity.Focus] / hour}-${densityMaxTimelineValue[TScaleDensity.Medium] / hour} hrs`,
  },
  {
    id: TScaleDensity.Focus,
    icon: densityToIcon[TScaleDensity.Focus],
    title: `${TScaleDensity.Focus}: ${densityMaxTimelineValue[TScaleDensity.Precise] / minute} Min. - ${densityMaxTimelineValue[TScaleDensity.Focus] / hour} hrs`,
  },
  {
    id: TScaleDensity.Precise,
    icon: densityToIcon[TScaleDensity.Precise],
    title: `${TScaleDensity.Precise}: ${densityMaxTimelineValue[TScaleDensity.Pinpoint] / minute}-${densityMaxTimelineValue[TScaleDensity.Precise] / minute} Min.`,
  },
  {
    id: TScaleDensity.Pinpoint,
    icon: densityToIcon[TScaleDensity.Pinpoint],
    title: `${TScaleDensity.Pinpoint}: <${densityMaxTimelineValue[TScaleDensity.Pinpoint] / minute} Min.`,
  },
];
