
import { Components, Theme } from '@mui/material/styles';

export const MuiStepper: Components<Omit<Theme, 'components'>>['MuiStepper'] = {
  styleOverrides: {
    root: {
      gap: '1rem',
    },
  },
};
