import videoPlaybackReducer from './video-playback/slice';
import videoPlaybackAvailReducer from './video-playback-avail/slice';
import videoPlaybackEventReducer from './video-playback-event/slice';
import videoPlaybackGpsReducer from './video-playback-gps/slice';
import videoPlaybackInitReducer from './video-playback-init/slice';
import videoPlaybackJ1939Reducer from './video-playback-j1939/slice';
import videoPlayerBookmarkReducer from './video-player-bookmark/slice';
import videoPlayerScaleReducer from './video-player-scale/slice';

export const videoPlayerStores = {
  videoPlayback: videoPlaybackReducer,
  videoPlaybackAvail: videoPlaybackAvailReducer,
  videoPlaybackEvent: videoPlaybackEventReducer,
  videoPlaybackGps: videoPlaybackGpsReducer,
  videoPlaybackInit: videoPlaybackInitReducer,
  videoPlaybackJ1939: videoPlaybackJ1939Reducer,
  videoPlayerBookmark: videoPlayerBookmarkReducer,
  videoPlayerScale: videoPlayerScaleReducer,
};
