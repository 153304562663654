import { TReduxTag } from '@th-common/enums/redux-tags.enum';
import {
  IUserGroup, IUserGroupCreate, IUserGroupPermissions, IUserGroupUpdate,
} from '@th-common/interfaces/user-groups/user-groups';
import { apiRoot } from '@th-common/store/api';

export const userGroupsApi = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getUserGroups: builder.query<IUserGroup[], void>({
      query: () => 'user-groups',
      providesTags: [
        TReduxTag.UserGroups,
      ],
    }),
    getUserGroupPermissions: builder.query<IUserGroupPermissions, number>({
      query: (groupId) => `user-groups/${groupId}`,
    }),
    getNewUserGroupPermissions: builder.query<IUserGroupPermissions, void>({
      query: () => 'user-groups/new-group',
    }),
    createUserGroup: builder.mutation<IUserGroup, IUserGroupCreate>({
      query: (create) => ({
        url: 'user-groups',
        method: 'POST',
        body: create,
      }),
      invalidatesTags: [
        TReduxTag.UserGroups,
      ],
    }),
    updateUserGroup: builder.mutation<IUserGroup, IUserGroupUpdate>({
      query: (update) => ({
        url: 'user-groups',
        method: 'PUT',
        body: update,
      }),
    }),
    deleteUserGroup: builder.mutation<void, number>({
      query: (groupId) => ({
        url: `user-groups/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        TReduxTag.UserGroups,
      ],
    }),
  }),
});
