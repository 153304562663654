import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { videoDownloadApi } from '@th-common/api/video-download.api';
import { IVideoInitData } from '@th-common/interfaces/video/init-data';

export interface IVideoPlaybackInitState {
  initJSONList: string[];
  initData: IVideoInitData[];
}

const initialState: IVideoPlaybackInitState = {
  initJSONList: [],
  initData: [],
};

export const slice = createSlice({
  name: 'videoPlaybackInit',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<IVideoPlaybackInitState>) => {
    builder
      .addMatcher(videoDownloadApi.endpoints.getVideoDownload.matchFulfilled, (state, { payload }) => {
        state.initJSONList = payload.init || [];
      })
      .addMatcher(videoDownloadApi.endpoints.getVideoDownloadInitData.matchFulfilled, (state, { payload }) => {
        state.initData = payload;
      });
  },
});

export default slice.reducer;
