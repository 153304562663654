import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import {
  createListenerMiddleware, isAnyOf, ListenerEffectAPI, ThunkDispatch, UnknownAction,
} from '@reduxjs/toolkit';
import {
  IDeviceStatusListNotification, IDeviceStatusNotification, IJobExecutionNotification, IJobExecutionUpdateNotification, TSignalRMethods,
} from '@th-common/enums/signalr-methods.enum';
import { config } from '@th-common/utils/config';
import { StorageUtils } from '@th-common/utils/storage';

import { signalRJobExecutionNotification, signalRJobHistoryUpdateNotification } from '@/app/app/fleets/modals/DeviceDetailsModal/stores/job-history/slice';
import { signalRDeviceStatusNotification } from '@/app/app/fleets/modals/DeviceDetailsModal/stores/status-history/slice';

import { initializeAuth, logout, renewAuth } from '../auth/slice';
import { signalRUpdateFleetDeviceStatuses } from '../fleet/slice';
import { signalRGeographicalQueryExecutionUpdateNotification } from '../geographical-query-details/slice';
import { signalRMarkedVideoUpdateNotification } from '../marked-video/slice';
import { setHubConnection, setHubConnectionInProgress, stopHubConnection } from '../signalr/slice';
import { RootState } from '../store';
import { signalRUpdateVideoPlaybackDeviceStatuses } from '../video-playback-devices/slice';
import { signalRVideoQueryUpdateNotification } from '../video-query/slice';

// eslint-disable-next-line @typescript-eslint/naming-convention
const DEFAULT_RETRY_DELAYS_IN_MILLISECONDS = [1000, 4000, 10000, null];

const subscribeToEvents = (
  hubConnection: HubConnection,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
): void => {
  hubConnection.on(TSignalRMethods.JobExecutionUpdateNotification, (data: IJobExecutionUpdateNotification) => {
    listenerApi.dispatch(signalRVideoQueryUpdateNotification(data));
    listenerApi.dispatch(signalRMarkedVideoUpdateNotification(data));
    listenerApi.dispatch(signalRGeographicalQueryExecutionUpdateNotification(data));
    listenerApi.dispatch(signalRJobHistoryUpdateNotification(data));
  });

  hubConnection.on(TSignalRMethods.DeviceStatusList, (data: IDeviceStatusListNotification[]) => {
    listenerApi.dispatch(signalRUpdateVideoPlaybackDeviceStatuses(data));
    listenerApi.dispatch(signalRUpdateFleetDeviceStatuses(data));
  });

  hubConnection.on(TSignalRMethods.DeviceStatusNotification, (data: IDeviceStatusNotification) => {
    listenerApi.dispatch(signalRDeviceStatusNotification(data));
  });

  hubConnection.on(TSignalRMethods.JobExecutionNotification, (data: IJobExecutionNotification) => {
    listenerApi.dispatch(signalRJobExecutionNotification(data));
  });
};

const isHubConnected = (hubConnection: HubConnection | null): boolean => {
  return !!hubConnection && hubConnection.state === 'Connected';
};

const stopSignalR = (
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
): Promise<void> => {
  const originalState = listenerApi.getOriginalState() as RootState;
  const hubConnection = originalState.signalR.hubConnection;

  if (isHubConnected(hubConnection)) {
    return hubConnection!.stop().then(() => {
      console.log('SignalR connection stopped');
    }).catch((error) => {
      console.error('Error stopping SignalR connection:', error);
    });
  }
  return new Promise((resolve) => {
    console.log('SignalR connection already stopped');
    resolve();
  });
};

const startSignalR = (
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
): void => {
  const state = listenerApi.getState() as RootState;
  const hubConnectionStored = state.signalR.hubConnection;

  const token = state.auth.token;

  let retryCount = 0;

  const hubConnectionInProgress = state.signalR.hubConnectionInProgress;

  const apiBaseUrl = StorageUtils.search<{ apiBaseUrl: string }>('env', 'sessionStorage')?.apiBaseUrl;
  const baseUrl = apiBaseUrl?.replace(/\/api$/, '');

  if (!isHubConnected(hubConnectionStored) && !hubConnectionInProgress && !!token && !!baseUrl) {
    listenerApi.dispatch(setHubConnectionInProgress(true));

    const hubConnection = new HubConnectionBuilder()
      .withUrl(`${baseUrl}${config.hubUrl}`, {
        accessTokenFactory: () => token,
        skipNegotiation: false,
        withCredentials: false,
      })
      .withAutomaticReconnect()
      .build();

    listenerApi.dispatch(setHubConnection(hubConnection));

    subscribeToEvents(hubConnection, listenerApi);

    window.addEventListener('online', () => {
      listenerApi.dispatch(stopHubConnection());
      startSignalR(listenerApi);
    });

    window.addEventListener('offline', () => {
      listenerApi.dispatch(stopHubConnection());
    });

    hubConnection.start().then(() => {
      console.log('SignalR connection started');
      listenerApi.dispatch(setHubConnectionInProgress(false));
    }).catch((error) => {
      listenerApi.dispatch(setHubConnectionInProgress(false));

      let connectionError = error || new Error('SignalR connection was failed with unknown reason');

      switch (error.statusCode) {
        case 401:
          connectionError = new Error('Token expired');
          break;
        case 0:
        case undefined:
        case null:
          /* Temporary fix to get 401 error on negotiation connect until
                  github issue (https://github.com/dotnet/aspnetcore/issues/39079) will be closed (version 7 or 8)
                */
          if (error.message && error.message.includes('Status code \'401\'')) {
            connectionError = new Error('Token expired');
            break;
          } else {
            const delayInMilliseconds = DEFAULT_RETRY_DELAYS_IN_MILLISECONDS[retryCount];
            if (delayInMilliseconds) {
              retryCount++;
              setTimeout(() => {
                // Trigger hub reconnect
                listenerApi.dispatch(stopHubConnection());
                startSignalR(listenerApi);
              }, delayInMilliseconds);
              break;
            } else {
              connectionError = new Error('Token expired');
              break;
            }
          }
      }

      console.error('Error starting SignalR connection:', connectionError);
    });
  }
};

export const signalRListenerMiddleware = createListenerMiddleware();
signalRListenerMiddleware.startListening({
  matcher: isAnyOf(
    initializeAuth,
    renewAuth,
    logout,
    stopHubConnection,
  ),
  effect: (action, listenerApi) => {
    switch (action.type) {
      case logout.type:
        listenerApi.dispatch(stopHubConnection());
        break;
      case stopHubConnection.type:
        stopSignalR(listenerApi);
        break;
      case renewAuth.type:
        // Trigger hub reconnect
        listenerApi.dispatch(stopHubConnection());
        startSignalR(listenerApi);
        break;
      default:
        startSignalR(listenerApi);
    }
  },
});
